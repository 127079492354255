import React from "react";
import Dotdotdot from 'react-dotdotdot'
import { Link } from "react-router-dom";
import { WebsiteLink } from "../../functions/WebsiteLink";

const Card = ({ article }) => {

  const imageUrl =
    process.env.NODE_ENV !== "development"
      ? article.image.url
      : process.env.REACT_APP_BACKEND_URL + article.image.url;
    return (
      <div className="articleLink">
        <div className="uk-card uk-card-muted">
            <div className="uk-card-media-top" style={{ backgroundColor: article.thumbnail_colour }}>
              <WebsiteLink website={article} />
              <Link to={`/article/${article.id}`} className="uk-link-reset">
              <img
                src={imageUrl}
                alt={article.image.url}
              />
              </Link>
            </div>
            <Link to={`/article/${article.id}`} className="uk-link-reset">
              <div className="uk-card-body">
                <h3 id="title">
                  {article.title}
                </h3>
                <Dotdotdot clamp={2}>
                    <p>{article.content}</p>
                </Dotdotdot>
              </div>
            </Link>
        </div>
      </div>
    );
};


export default Card;