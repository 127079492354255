import React from "react";
import Pagination from "react-router-pagination";

const Nav = ( articleID ) => {

  return (
      <Pagination
        totalPages={6}
        pageNumber={articleID.articleID}
        spread={6}
        match={
          {
            path: '/article/:pageNumber'
          }
        }
      />
  );
};


export default Nav;