import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import Nav from "../../components/Nav";
import ARTICLE_QUERY from "../../queries/article/article";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import { WebsiteLink } from "../../functions/WebsiteLink";


const Article = () => {
  let { id } = useParams();
  return (

      <Query query={ARTICLE_QUERY} id={id}>
        {({ data: { article } }) => {
          const bannerImageUrl =
            process.env.NODE_ENV !== "development"
              ? article.banner_image.url
              : process.env.REACT_APP_BACKEND_URL + article.banner_image.url;
          const articleImage =
            process.env.NODE_ENV !== "development"
              ? article.content_image.url
              : process.env.REACT_APP_BACKEND_URL + article.content_image.url;

              return (

                <article className="uk-section">

                  <section
                    id="banner"
                    className="banner--image uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light uk-padding uk-margin"
                    style={{ backgroundImage: "url(" + bannerImageUrl + ")"}}
                  >
                  </section>

                  <section className="articleNav uk-section uk-padding-remove">
                    <div className="uk-container uk-container-large">
                      <Nav articleID={id} />
                      <WebsiteLink website={article} />
                    </div>
                  </section>

                  <section className="articleContent uk-section">
                    <div className="uk-container uk-container-medium">
                      <div data-uk-grid className="uk-flex">
                        <div className="uk-width-1-1 uk-width-1-2@s">
                          <Moment format="MMM Do YYYY">{article.put_live_date}</Moment>
                          <h1>{article.title}</h1>
                          <ReactMarkdown source={article.content} />
                        </div>
                      </div>
                    </div>
                    <img className="articleImage" src={articleImage} alt="Article" />
                  </section>

                  <section className="articleGallery uk-section">
                    <div className="uk-container uk-container-medium">
                      {article.gallery.map((image, index) => (
                        <img 
                          key={index}
                          src={
                          process.env.NODE_ENV !== "development"
                            ? image.url
                            : process.env.REACT_APP_BACKEND_URL + image.url
                          } 
                          alt="Gallery"
                          />
                      ))}
                    </div>
                  </section>

                  <section className="articleNav uk-section uk-padding-remove">
                    <div className="uk-container uk-container-large">
                      <Nav articleID={id} />
                      <WebsiteLink website={article} />
                    </div>
                  </section>

                </article>
              
              );

        }}
      </Query>

  );
};

export default Article;