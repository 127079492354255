import gql from "graphql-tag";

const ARTICLE_QUERY = gql`
  query Articles($id: ID!) {
    article(id: $id) {
      id
      publish
      slug
      title
      put_live_date
      content
      banner_image {
        url
      }
      gallery {
        url
      }
      content_image {
        url
      }
      website
      category {
        id
        name
      }
    }
  }
`;

export default ARTICLE_QUERY;