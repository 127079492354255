import React from "react";
import Articles from "../../components/Articles";
import Query from "../../components/Query";
import ARTICLES_QUERY from "../../queries/article/articles";
import GLOBAL_QUERY from "../../queries/global/global";
import ReactMarkdown from "react-markdown";

const Home = () => {
  return (

    <main className="uk-section">

      <section id="banner" className="banner--home uk-section">
        <div className="uk-container uk-container-large">
          <div className="banner__content uk-padding">
            <Query query={GLOBAL_QUERY} id={null}>
              {({ data: { globalOption } }) => {
                return <ReactMarkdown source={globalOption.banner_text} />;
              }}
            </Query>
          </div>
        </div>
      </section>

      <div id="articles" className="uk-section">
        <div className="uk-container uk-container-large">
          <Query query={ARTICLES_QUERY}>
            {({ data: { articles } }) => {
              return <Articles articles={articles} />;
            }}
          </Query>
        </div>
      </div>

    </main>

  );
};

export default Home;