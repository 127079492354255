import React from "react";
import Query from "../Query";
import GLOBAL_QUERY from "../../queries/global/global";
import { Link } from "react-router-dom";

const Header = ({ colour }) => {
  return (
    <Query query={GLOBAL_QUERY} id={null}>
      {({ data: { globalOption } }) => {
        const resumeUrl =
          process.env.NODE_ENV !== "development"
            ? globalOption.resume.url
            : process.env.REACT_APP_BACKEND_URL + globalOption.resume.url;
		      	return (
		      		<header className={"uk-section " + colour}>
								<div className="uk-container uk-container-large">
									<div data-uk-grid className="uk-flex uk-flex-center">
											<div className="uk-width-1-1 uk-width-1-2@s">
							        	<Link to="/">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.1 51.1" width="200" alt="Mark Bucknall">
													  <g>
													    <g id="Creative_Web_Developer">
													      <path d="M12.2,44.8,15,46a7.4484,7.4484,0,0,1-7.3,5C2.8,51,0,47.5,0,43.2c0-4.4,2.9-8.3,8-8.3a7.1492,7.1492,0,0,1,6.5,3.5l-2.6,1.9a4.3854,4.3854,0,0,0-4-2.3C5,38,3.3,40.2,3.3,42.9c0,3,2,5,4.6,5A4.2947,4.2947,0,0,0,12.2,44.8Z" fill="#c1c1c1"/>
													      <path d="M17.3,35.3h6c3.5,0,5,2.2,5,4.6A4.3427,4.3427,0,0,1,25.4,44l3.9,6.7H25.5l-3.3-5.9H20.5v5.9H17.2V35.3Zm3.2,3V42h2.4A1.8458,1.8458,0,0,0,25,40.1c0-1.1-.7-1.8-2-1.8Z" fill="#c1c1c1"/>
													      <path d="M41.2,38.4H34.6v3h5.6v3.1H34.6v3.1h6.8v3.1H31.3V35.3h9.9v3.1Z" fill="#c1c1c1"/>
													      <path d="M54.6,50.7l-1.2-3.2H47.5l-1.2,3.2H42.8l6-15.4h3.1L58,50.7Zm-6-6.2h3.6l-1.8-4.9Z" fill="#c1c1c1"/>
													      <path d="M64.9,38.4V50.7H61.6V38.4H56.9V35.3H69.6v3.1Z" fill="#c1c1c1"/>
													      <path d="M71.5,50.7V35.3h3.3V50.7Z" fill="#c1c1c1"/>
													      <path d="M88,35.3h3.5L85.6,50.7H82.5L76.6,35.3h3.5L84,46.1Z" fill="#c1c1c1"/>
													      <path d="M103.4,38.4H96.8v3h5.6v3.1H96.8v3.1h6.8v3.1H93.5V35.3h9.9v3.1Z" fill="#c1c1c1"/>
													      <path d="M128.4,35.3h3.5L127,50.7h-3.1l-3-9.6-3,9.6h-3.1l-4.9-15.4h3.5l3,10.3,3-10.3h3.1l3,10.3Z" fill="#c1c1c1"/>
													      <path d="M143.6,38.4H137v3h5.6v3.1H137v3.1h6.8v3.1H133.7V35.3h9.9v3.1Z" fill="#c1c1c1"/>
													      <path d="M146.6,35.3h6.1c2.8,0,4.3,1.9,4.3,3.8a3.3579,3.3579,0,0,1-2.5,3.3,3.6079,3.6079,0,0,1,3.1,3.7c0,2.4-1.9,4.6-5.2,4.6h-5.9V35.3Zm3.2,3v3.2h2.1a1.6877,1.6877,0,0,0,1.9-1.7c0-.9-.7-1.5-1.8-1.5Zm0,5.7v3.7h2.4c1.3,0,2.2-.7,2.2-1.9,0-1.1-.8-1.8-2.1-1.8Z" fill="#c1c1c1"/>
													      <path d="M165.2,50.7V35.3h5.4c5.2,0,7.8,3.7,7.8,7.5a7.7078,7.7078,0,0,1-8.1,7.9Zm3.3-12.3v9.2h2.1a4.4,4.4,0,0,0,4.6-4.6,4.3748,4.3748,0,0,0-4.5-4.6Z" fill="#c1c1c1"/>
													      <path d="M190.9,38.4h-6.6v3h5.6v3.1h-5.6v3.1h6.8v3.1H181V35.3h9.9v3.1Z" fill="#c1c1c1"/>
													      <path d="M203.9,35.3h3.5l-5.9,15.4h-3.1l-5.9-15.4H196l3.9,10.8Z" fill="#c1c1c1"/>
													      <path d="M219.3,38.4h-6.6v3h5.6v3.1h-5.6v3.1h6.8v3.1H209.4V35.3h9.9v3.1Z" fill="#c1c1c1"/>
													      <path d="M225.5,47.6h6.1v3.1h-9.4V35.3h3.3Z" fill="#c1c1c1"/>
													      <path d="M248.7,43a7.9025,7.9025,0,1,1-15.8,0,7.9025,7.9025,0,1,1,15.8,0Zm-3.2,0c0-2.9-1.9-5-4.6-5s-4.6,2.1-4.6,5,1.9,5,4.6,5A4.75,4.75,0,0,0,245.5,43Z" fill="#c1c1c1"/>
													      <path d="M251.3,35.3H257a4.5046,4.5046,0,0,1,4.8,4.8,4.9311,4.9311,0,0,1-5.1,5.1h-2.2v5.6h-3.3V35.3Zm3.3,3v3.9h2a1.8881,1.8881,0,0,0,2-2,1.7083,1.7083,0,0,0-1.9-1.8h-2.1Z" fill="#c1c1c1"/>
													      <path d="M274.1,38.4h-6.6v3h5.6v3.1h-5.6v3.1h6.8v3.1H264.2V35.3h9.9v3.1Z" fill="#c1c1c1"/>
													      <path d="M277.1,35.3h6c3.5,0,5,2.2,5,4.6a4.3427,4.3427,0,0,1-2.9,4.1l3.9,6.7h-3.8L282,44.8h-1.7v5.9h-3.2Zm3.3,3V42h2.4a1.8458,1.8458,0,0,0,2.1-1.9c0-1.1-.7-1.8-2-1.8Z" fill="#c1c1c1"/>
													    </g>
													    <g id="Mark_Bucknall">
													      <path d="M17.1.4h5.3V20.7H18.1V8.1l-4.2,8.8H9.8L5.6,8.1V20.7H1.3V.4H6.6l5.3,11.5Z" fill="#fff"/>
													      <path d="M43.9,20.7l-1.6-4.2H34.6l-1.5,4.2H28.5L36.4.4h4.1l8,20.3ZM36,12.5h4.8L38.4,6.1Z" fill="#fff"/>
													      <path d="M54.4.4h7.9c4.6,0,6.6,2.9,6.6,6.1A5.8186,5.8186,0,0,1,65,11.9l5.2,8.9h-5L60.8,13H58.6v7.8H54.3V.4Zm4.3,3.9V9.1h3.1a2.5044,2.5044,0,0,0,2.8-2.5c0-1.5-1-2.3-2.6-2.3Z" fill="#fff"/>
													      <path d="M76.4.4h4.3V8.9L88.4.4h5.8l-9.3,9.7L95.1,20.7H89.2l-8.5-9.1v9.1H76.4Z" fill="#fff"/>
													      <path d="M111.3.4h8.1c3.7,0,5.7,2.5,5.7,5a4.4087,4.4087,0,0,1-3.3,4.4,4.939,4.939,0,0,1,4.1,4.9c0,3.1-2.6,6.1-6.9,6.1h-7.7V.4Zm4.3,3.9V8.5h2.8a2.2445,2.2445,0,0,0,2.5-2.2c0-1.2-.9-2-2.4-2Zm0,7.5v4.9h3.2c1.7,0,2.9-.9,2.9-2.5,0-1.5-1.1-2.4-2.8-2.4Z" fill="#fff"/>
													      <path d="M144.5.4h4.3v13c0,4.6-2.6,7.7-8.1,7.7s-8.1-3.2-8.1-7.7V.4h4.3V13.1c0,2.5,1.5,4,3.8,4,2.4,0,3.8-1.5,3.8-4V.4Z" fill="#fff"/>
													      <path d="M171.4,13l3.7,1.6a9.8208,9.8208,0,0,1-9.6,6.6c-6.4,0-10.1-4.7-10.1-10.3,0-5.8,3.9-10.9,10.5-10.9a9.3407,9.3407,0,0,1,8.5,4.6L171,7.1a5.928,5.928,0,0,0-5.3-3c-3.8,0-6,2.9-6,6.5,0,3.9,2.6,6.5,6.1,6.5A6.0114,6.0114,0,0,0,171.4,13Z" fill="#fff"/>
													      <path d="M181.5.4h4.3V8.9L193.5.4h5.8L190,10.1l10.1,10.6h-5.9l-8.5-9.1v9.1h-4.3V.4Z" fill="#fff"/>
													      <path d="M219.6.4h4.3V20.7h-4.6L210.4,7.8V20.7h-4.3V.4h4.6l8.9,13.4Z" fill="#fff"/>
													      <path d="M245.4,20.7l-1.6-4.2h-7.7l-1.5,4.2H230L237.9.4H242l8,20.3Zm-7.9-8.2h4.8l-2.4-6.4Z" fill="#fff"/>
													      <path d="M260.2,16.6h8.1v4.1H255.9V.4h4.3Z" fill="#fff"/>
													      <path d="M278.9,16.6H287v4.1H274.6V.4h4.3Z" fill="#fff"/>
													    </g>
													  </g>
													</svg>
							          </Link>
							        </div>
							        <div className="uk-width-1-1 uk-width-1-2@s">
				                <div className="social uk-flex uk-flex-left uk-flex-right@s" data-uk-grid>
					          			<a href={globalOption.linkedin} target="_blank" rel="noopener noreferrer">
					          				<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" className="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
						          		</a>
						          		<a href={"mailto:" + globalOption.email} target="_blank" rel="noopener noreferrer">
						          			<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
						          		</a>
						          		<a href={resumeUrl} target="_blank" rel="noopener noreferrer">
						          			Resume
						          		</a>
						          	</div>
					          	</div>
			          	</div>
			          </div>
		        	</header>
	    	);
  		}}
  	</Query>
  );
};


export default Header;