import React from "react";
import Card from "../Card";

const Articles = ({ articles }) => {

  return (
    <section className="articles">
      <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
          {articles.sort((a, b) => a.id - b.id).map((article, i) => {
          	if(article.publish === true)
						return <Card article={article} key={`article__${article.id}`} />;
						return null;
          })}
      </div>
    </section>
  );
};

export default Articles;