import gql from "graphql-tag";

const GLOBAL_QUERY = gql`
  query GlobalOptions {
    globalOption {
      logo {
        url
      }
      resume {
        url
      }
      email
      linkedin
      banner_text
      signup
      copyright
    }
  }
`;

export default GLOBAL_QUERY;