import React, { useEffect } from 'react';
import { 
  Switch, 
  Route, 
  useLocation, 
  withRouter 
} from "react-router-dom";
import Header from "../../components/Header";
import Articles from "../Articles";
import Article from "../Article";
import Category from "../Category";
import Footer from "../../components/Footer";

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)


function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Header colour="black" />
            <Articles />
          </Route>
          <Route path="/article/:id" exact>
            <Header colour="white" />
            <Article />
          </Route>
          <Route path="/category/:id" exact>
            <Header colour="white" />
            <Category />
          </Route>
          <Route path="/">
            <Header colour="black" />
            <Articles />
          </Route>
        </Switch>
        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;