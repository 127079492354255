import React from "react";
import Query from "../Query";
import GLOBAL_QUERY from "../../queries/global/global";
import ReactMarkdown from "react-markdown";

const Footer = () => {
  return (
    <Query query={GLOBAL_QUERY} id={null}>
      {({ data: { globalOption } }) => {
	      	return (
	      		<footer className="uk-section">
							<div className="uk-container uk-container-large">
								<div className="signup">
									<ReactMarkdown source={globalOption.signup} />
								</div>
								<div className="copyright">
									<ReactMarkdown source={globalOption.copyright} />
								</div>
		          </div>
	        	</footer>
	    	);
  		}}
  	</Query>
  );
};


export default Footer;