import gql from "graphql-tag";

const ARTICLES_QUERY = gql`
  query Articles {
    articles {
      id
      publish
      slug
      title
      put_live_date
      content
      image {
        url
      }
      thumbnail_colour
      website
      category {
        id
        name
      }
    }
  }
`;

export default ARTICLES_QUERY;